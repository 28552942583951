@font-face {
  font-family: Futura;
  font-weight: 500;
  src: url("./fonts/Futura-Medium-01.ttf") format("truetype");
}
@font-face {
  font-family: Futura;
  font-weight: 500;
  font-style: italic;
  src: url("./fonts/Futura-MediumItalic-02.ttf") format("truetype");
}
@font-face {
  font-family: Futura;
  font-weight: 700;
  src: url("./fonts/Futura-Bold-03.ttf") format("truetype");
}
@font-face {
  font-family: Futura;
  font-weight: 500;
  font-stretch: condensed;
  src: url("./fonts/Futura-CondensedMedium-04.ttf") format("truetype");
}
@font-face {
  font-family: Futura;
  font-weight: 700;
  font-stretch: condensed;
  src: url("./fonts/Futura-CondensedExtraBold-05.ttf") format("truetype");
}

.bold {
  font-weight: 700;
}
.italic {
  font-style: italic;
}
.medium {
  font-weight: 500;
}
.condensed {
  font-stretch: condensed;
}

body {
  margin: 0;
  padding: 0;
  font-family: Futura;
  font-weight: 500;
  color: white;
}

.square {
  position: relative;
  width: 50%;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 2.4rem;
  font-weight: 500;
  text-transform: uppercase;
}

h3 {
  font-size: 1.35rem;
  font-weight: 500;
  text-transform: uppercase;
}

h6 {
  font-size: 0.8rem;
}

.root-container {
  min-height: 100vh;
}
@media (min-width: 768px) {
  .root-container {
    height: 100vh;
  }
  .col-md-17 {
    -ms-flex: 0 0 14.2857%;
    flex: 0 0 14.2857%;
    max-width: 14.2857%;
  }
  .col-md-20 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.dropdown-menu.show {
  opacity: 1 !important;
}

.bar-width-percent {
  width: 30%;
}
/* 
@media (min-width: 576px) { 
  .bar-width-percent{
    width:30%
  }
 } */
.menu-text {
  font-size: 20px;
}
@media (min-width: 768px) {
  .bar-width-percent {
    width: 50%;
  }
  .menu-text {
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .bar-width-percent {
    width: 34%;
  }
  .menu-text {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .bar-width-percent {
    width: 28%;
  }
  .menu-text {
    font-size: 18px;
  }
}
@media (min-width: 1400px) {
  .bar-width-percent {
    width: 22%;
  }
  .menu-text {
    font-size: 22px;
  }
}
